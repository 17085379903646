import './CausesHeader.css'

const CausesHeader = () => {
    return (  
        <div className='causes__header-container '>
          <div className='causes-texts'>
    
              <h1 className='causes__title'>
              Causes
              </h1> 
              <p className='causes-paragraph'>Caring for vulnerable children through advocacy, healthcare and education. </p>
    
          </div>
        </div> 
        );
}
 
export default CausesHeader;
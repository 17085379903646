
import joinImage from "../../assets/images/joinimage.png";
import './join.css'



export default function Join () {
    return ( <div className="join_container">
        <div>
            <div >
                <h1 className="join_title">Join our team <br /> today</h1>
            </div>
<div className="join_content">


            <div className="join_image-container">
           <img className="join_image" src={joinImage} alt="joinimage" />
            </div>
            
        <div className="join_text-content">

           

            <h2 className="join_text_title">Want to become <br /> a volunteer?</h2>
            <p className="join_paragraph">Our projects are tailored towards solving the most pressing issues on health system policy, health literacy, inequality,
                and social discrimination in rural communities and IDP camps in Nigeria.</p>
                <div className="Join-list">
                   <p>You will gain hands-on leadership experience</p>
                   <p>You have room to experess your gifts in its full capacity. </p>
                   <p>You get to work with an amazing team of young leaders, just like you.</p>
                </div>
            {/* <Accordion /> */}
            <button> <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_GmNwTgUIker33aP72EO2dBmygfNy4f4pA4c4X-sGtXxRbg/viewform" target="_blank" rel="noreferrer">Volunteer</a></button>

            </div>

        </div>
        </div>
 
       
    </div>  );
}
 


import './donate.css'
import Layout from '../Layout';
import DonateHeader from '../donate-container/donateHeader/DonateHeader';


export default function Donate() {

 
    return (


        <Layout>
      <div className='donate__container'>

        <DonateHeader />

    </div> 
    </Layout>
    );
}



import './accordion.css'
import { useState } from 'react';



function Accordion () {
    const [selected, setSelected] = useState(null)
    const toggle = (i) => {
        if (selected === i) {
            return setSelected(null)
        }
        setSelected(i)
    }

    return ( <div className="wrapper">
        <div className="accordion">
          {data.map((item, i) => (
            <div className='item'>
                <div className='title' onClick={()=> toggle(i)}>
                    <h2>{item.question}</h2>
                    <span>{selected === i ? '-' : '+'}</span>
                    </div>
                    <div className={selected === i ? 'content show' : 'content'}>{item.answer}</div>
                </div>
          ))}
        </div>
        
        </div> );
}
 
const data = [
    {
        question: 'The Volunteer Teaching Program (VTP)',
        answer: ' The Volunteer Teaching Program (VTP) has over a hundred children educated on Mental health awareness and sex education through our volunteer base teachings. Volunteers are trained and over 2000 girl children have received sanitary pads and materials to encourage proper hygiene both in Government secondary schools, rural communities and IDP camps around Abuja. ',
    },
    {
        question: 'Hearts to hands HTH',
        answer: ' Hearts to hands HTH project has impacted over 1000 internally displaced children by distributing relief materials and organizing Christmas fun fair and outreaches. ',
    },
    {
        question: 'The Healthy Child Medical Outreach (HCMO)',
        answer: ' The HCMO is a pilot event. It is the core of our mission of Nedeevah to specifically provide malaria drugs and treatments to children, attempting to mitigate the rising costs of anti-malaria drugs. With the rising effect of the Boko- Haram insurgency and increased number of IDP camps in Nigeria, The rate of malaria infection is alarming and children die from avoidable deaths caused by Mosquitoes bites and lack of access to good healthcare. there is a need to make sure that even underprivileged communities can care for themselves. Because of this need, this project was born. We have been able to raise awareness about the outreach and reached out to health professionals  for volunteers and fundings. We give out anti-malaria drugs, deworming drugs, run malaria tests for children and provide treatments too. ',
    },
   
]



export default Accordion;
import './causes.css'
// import { Navbar } from '../container';
import Layout from '../Layout';
import { CausesHeader, CausesCards } from '../causes-container';

const Causes = () => {
    
    return ( 

    <Layout>
        
    <div className='causes_container'>
   
        <CausesHeader />
        <CausesCards />
    </div>
  
    </Layout>
    );
}
 
export default Causes;
import { Gallery } from "../../components";
import "./gallerySection.css";

const GallerySection = () => {
    return ( <div className="container">
        <h1 className="gallery-heading">Gallery</h1>
        <p >Swipe left to see more</p>
        <Gallery />
    </div>  );
}
 
export default GallerySection;
import Accordion from "../../components/accordion/Accordion";
import projectImage from "../../assets/images/projectimage.png";
import './project.css'

export default function Project () {
    return ( <div className="project_container">
        <div>
            <div >
                <h1 className="project_title">How we're making <br /> a difference</h1>
            </div>
<div className="project_content">


            <div className="project_image-container">
           <img className="project_image" src={projectImage} alt="projectimage" />
            </div>
            
        <div className="project_text-content">

           

            <h2 className="accordion_title">Projects</h2>
            <p className="title_paragraph">Our projects are tailored towards solving the most pressing issues on health system policy, health literacy, inequality,
                and social discrimination in rural communities and IDP camps in Nigeria.</p>
            <Accordion />
            <button className="donate__button">
                    <a href="https://flutterwave.com/pay/pvw2fyvkalpi" target="_blank" rel="noreferrer">Donate</a>
                  </button>

            </div>

        </div>
        </div>
       
       
    </div>  );
}
 

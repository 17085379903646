import React, { useState } from "react";
import { Link } from 'react-router-dom'
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/nficon3.png';




const Menu = () => (


    <>
          <ul>
          <li className="nav-links"> <Link  to='/'>Home</Link> </li>
            <li className="nav-links"> <Link  to='/about'>About</Link> </li>
            <li className="nav-links"> <Link  to='/causes'>Causes</Link> </li>
            <li className="nav-links"> <Link  to='/contact'>Contact</Link> </li>
            <li className="nav-links"> <Link to='/donate'>Donate</Link> </li>
        </ul>
    </>
)



export default function Navbar() {
    const [toggleMenu, setToggleMenu] = useState(false);



  
   
    return(
      
        <div className="navbar__container"> 
        
        <div className="nav-logo-div">
        <Link to='/'>
                <div className="navbar__container-logo">
                <img className="navbar-logo" src={logo} alt="logo" /> 
                </div>
       </Link>
        </div>
   
                <div className="navbar__container-list">
                <Menu />
                </div>
    

        <div className="navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#000" size={27} onClick={() =>  setToggleMenu(false) } />
          : <RiMenu3Line color="#000" size={27} onClick={() =>  setToggleMenu(true) } />}
        {toggleMenu && (
        <div className="navbar-menu_container scale-up-center" >
          <div className="navbar-menu_container-list">
           <Menu />
          </div>
                     
              </div>
            )
            }
          </div>

  

         </div>
    )
}

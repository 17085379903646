import './aboutThree.css'

const AboutThree = () => {
    return ( <div className='aboutThree-container'>
        <div className='aboutThree-content'>
            <h1 className='teamHeading'>   Meet The Team </h1>
            <p className='teamParagraph heading-paragraph'> The Nedeevah team is committed to the transformation of underpriviledged
                communities in a way that would enable the african child grow and
                reach their full potential. </p>
            <div className='aboutThree-images'>
                <div className='aboutThree-image gridTop'>
                <div className='aboutThree-img gridTopOne'><div className='gridNameContainer'><p className='gridNames'>Claire Stephen</p></div></div>
                <div className='aboutThree-img gridTopTwo'><div className='gridNameContainer'><p className='gridNames'>Gracemary Stephen</p></div></div>
                {/* <div className='aboutThree-img gridTopThree'></div> */}
            </div>
                <div className='aboutThree-image gridBottom'>
                <div className='aboutThree-img gridBottomOne'><div className='gridNameContainer'><p className='gridNames'>Benson Onoja</p></div></div>
                <div className='aboutThree-img gridBottomTwo'><div className='gridNameContainer'><p className='gridNames'>Saratu Dawaki</p></div></div>
                </div>
            </div>
        </div>
        </div> );
}
 
export default AboutThree;
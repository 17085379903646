
import ReactDOM from 'react-dom/client';
import './index.css';
// eslint-disable-next-line 
import App from './App';
import React from 'react';
import Home from './pages/Home';
import About from './pages/About';
import Causes from './pages/Causes';
import Contact from './pages/Contact';
import Donate from './pages/Donate';
import ScrollToTop from './scrollToTop';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    
    <BrowserRouter>
<ScrollToTop>
    <Routes>
      <Route path='/' element= {<Home />}></Route>
      <Route path='/about' element= {<About />}> </Route>
      <Route path='/causes' element= {<Causes />}> </Route>
      <Route path='/contact' element= {<Contact />}> </Route>
      <Route path='/donate' element= {<Donate />}> </Route>
    </Routes>
</ScrollToTop>   
    </BrowserRouter>
    

);

 

import './donateHeader.css';

const DonateHeader = () => {
    return ( <div>
                <div className='donate-header'>



<div className='donate-cta'>

    
          <h1 className='donate__title'>Give</h1>
          <p className='donate-paragraph'>The future can be better if you join us to give. Be that person who does something today.</p>
    {/* <PaymentHook /> */}

    <button className="donate__button"
            >
            <a href="https://flutterwave.com/pay/pvw2fyvkalpi" target="_blank" rel="noreferrer">Donate</a>
          </button>
     </div>

    </div>

    
    </div> );
}
 
export default DonateHeader;
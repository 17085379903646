import './contactForm.css'
import React, { useRef } from 'react';
import  emailjs  from '@emailjs/browser';
import {  useInView } from "framer-motion";
import { AiOutlineMail, AiFillPhone,  } from 'react-icons/ai';
import { TiLocation } from 'react-icons/ti';


export default function ContactForm() {

    const ref = useRef(null);
    const isInView = useInView( ref );

    const form = useRef();


    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_itl9p1e', 'template_auq534d', form.current, 'y9Etl84o1R6Heaoap')
        .then((result) => {
            console.log(result.text);
            alert('Message Sent');
        }, (error) => {
            console.log(error.text);
            alert('Error');
        }); 
    e.target.reset()
    }
   

    return (
    <div className='contact-form-container' id='Contact'>

        <div className='contact-form-content'>

     

       <div  ref={ref} style={{ transform: isInView ? "none" : "translateY(100px)",
          opacity: isInView ? 1 : 0,
          transitionDuration: "1s" }} >

        </div> 

        <div  ref={ref} style={{ transform: isInView ? "none" : "translateY(200px)",
          opacity: isInView ? 1 : 0,
          transitionDuration: "1s" }} className='form-container'>
            
        
        <form ref={form} onSubmit={sendEmail}>
        <label for="name">Name</label>
        <input type="text" placeholder='Name' name="name" required/>
        <label for="email">Email</label>
        <input type="email" id="email" placeholder='Email'  required="" 
        title="Not Valid" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" name="email" />
        
        <label for="message">Message</label>
        <textarea className='message-field' type="text" placeholder='Your message here' name="message" required/>
         <button className='contact-button'> Send Email </button>

        </form>

       
        </div>

        <div className='contact-addresses'>
        

        <h2 className='contact-heading'>Contact Us</h2>

            <div className='contact-icons contact'>
                <div className='footer-links' > <AiOutlineMail className='social-icons' color="black" size={30} /> <p className='footer-text'> nedeevahfoundation@gmail.com</p></div>
                <div className='footer-links'> <AiFillPhone className='social-icons' color="black" size={30} /> <p className='footer-text'> +2347055243155</p></div>
                <div className='footer-links'> <TiLocation className='social-icons' color="black" size={30} /> <p className='footer-text'> No 8119 Nzube Estate lokogoma </p></div>
            </div>



        </div>

        </div>
    </div> );
}
 

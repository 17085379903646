import sdg_Logo from "../../assets/images/sdgs/E_SDG_logo_without_UN_emblem_horizontal_CMYK 1.png";
import sdg_img_three from "../../assets/images/sdgs/E_SDG_Icons-03.png";
import sdg_img_four from "../../assets/images/sdgs/E_SDG_Icons-04.png";

import './sdg.css';

export default function Sdg () {
    return (
        <div className="sdg_container">

            <div className="sdg_content" >
                <img className="sdgLogo" src={sdg_Logo} alt="sdgLogo" />
                <p className="">Our organisation's impact in line with the 2030 Agenda for Sustainable Development Goals SDG 3 and 4.</p>  
            </div>

            <div className="sdg_info">

            <div className="goals"> <img className="sdg-three sdg-icon_size" src={sdg_img_three} alt="sdg-three" /> 
            <p className="sdg_text"> At Nedeevah aid Foundation we firmly believe in the power of Goal 3 to transform lives. Through our grassroots initiatives and partnerships with local communities, we strive to improve healthcare access, promote preventive care, and raise awareness about crucial health issues. 
In collaboration with local healthcare providers and volunteers, we conduct medical camps, provide vaccinations, and offer essential healthcare services to underserved populations. By training healthcare workers, empowering local communities, and advocating for better health policies, we contribute to the achievement of Goal 3.</p>
             </div>

                <div className="goals"> <img className="sdg-four sdg-icon_size" src={sdg_img_four} alt="sdg-four" />
                <p className="sdg_text">At Nedeevah aid Foundation we firmly believe that education is a fundamental right, regardless of one's socioeconomic background. Through our educational programs, scholarships, and capacity-building initiatives, we strive to promote Goal 4 and empower individuals with the tools they need to thrive.</p>
            </div>
            
            </div>

        </div>
    )
} 
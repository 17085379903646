import iykeReview from '../../assets/images/reviews/iyke-review.png'
import maliReview from '../../assets/images/reviews/mali-review.png'
import saratuReview from '../../assets/images/reviews/saratu-review.png'
// import debbyReview from '../../assets/images/reviews/saratu-review.png'


export const sliderData = [
  
    {
      id: 1,
    
    name: 'Iyke Ibeh',
    occupation: 'Photographer',
    linkImg: iykeReview,
    review: 'It’s great that I get to capture beautiful moments & see hope manifest right before my eyes.' ,
    },
    {
      id: 2,
   
      name: 'Saratu Dawaki',
      occupation: 'Artiste',
      linkImg: saratuReview,
      review: 'Being a part of Nedeevah has given me moments & experiences that I will treasure for life.' ,
    },
    {
      id: 3,
   
      name: 'Akwunte Amali',
      occupation: 'UI/UX Developer',
      linkImg: maliReview,
      review: 'Nedeevah Foundation is doing amazing work with their outreach programs to underserved communities.' ,
    },
    // {
    //   id: 4,
   
    //   name: 'Deborah Inyang',
    //   occupation: 'Digital Design',
    //   linkImg: debbyReview,
    //   review: 'Nedeevah has been a great channel for helping children in need. As a lover of children, I am thankful to be a part of it.' ,
    // },
    // {
    //   id: 5,

    //   name: 'George Weasley',
    //   occupation: 'occupation',
    //   linkImg: logo,
    //   review: 'Our projects are tailored towards solving the most pressing issues on health system policy, health literacy, inequality, and social discrimination in rural communities and IDP camps in Nigeria' ,
    // },
    // {
    //   id: 6,
  
    //   name: 'Severus Snape',
    //   occupation: 'occupation',
    //   linkImg: logo,
    //   review: 'Our projects are tailored towards solving the most pressing issues on health system policy, health literacy, inequality, and social discrimination in rural communities and IDP camps in Nigeria' ,
    // },
  ];
  
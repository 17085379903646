
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './slidercomp.css';
import { sliderData } from './data';


export default function Slidercomp() {

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

 

  return (
    <div className="slidercomp-container">

      <div className='slider-content'>
     
      <Slider {...settings}>
        {sliderData.map((item) => (
          <div className="card">
            <div className="card-content">

              <div>
                <p className='card-paragraph'>{item.review}</p>
              </div>

              <div className='card-persona-info'>
             
              <div className='card-image-div'>
                <img className='card-image'

                src={item.linkImg}
                alt='img'
              />
              </div>
              
              <div className='card-base-text'>
              <h3>{item.name}</h3>
              <span className="occupation">{item.occupation}</span>
              </div>
             
              </div>

            </div>
          </div>
        ))}
      </Slider>
    </div>

    </div>      
  );
}


import './aboutHeader.css'

const AboutHeader = () => {
    return (  
        <div className='about__header-container '>
          <div className='about-texts'>
    
              <h1 className='about__title'>
              About Us
              </h1> 
              <p className='about-paragraph'>Nedeevah Foundation is an NGO dedicated to serving the needs of vulnerable and displaced children through clothing, provision of anti-malaria drugs, and health inclusion for children in Nigeria using the vehicle of advocacy. </p>
    
          </div>
        </div> 
        );
}
 
export default AboutHeader;
import vision from '../../assets/images/vision-img.jpeg'
import mission from '../../assets/images/mission-img.png'
import './aboutOne.css'
const AboutOne = () => {
    return ( <div className="aboutOne-container ">

        <div className='aboutOne-content vision'>

       <div className='aboutOne-img'>
       <img  src={vision} alt='vision' />
       </div>
      

        <div className='aboutOne-texts'>
            <h1 className='aboutOneHeading'>Vision</h1>
            <p className=' paragraph-two vision-paragraph'>Our vision is a world where every child has equal access to healthcare and education, enabling them to reach their full potential and build a brighter future.</p> 
        </div>
        
        </div>

        <div className='aboutOne-content mission'>
            
            <div>
            <img className='aboutOne-img' src={mission} alt='vision' />
            </div>
           
     
             <div className='aboutOne-texts'>
                 <h1 className='aboutOneHeading'>Mission</h1>
                 <p className='paragraph-two mission-paragraph'>Our mission is to provide underprivileged children with comprehensive healthcare and quality education, ensuring their holistic development and empowering them to break the cycle of poverty. Through strategic partnerships, advocacy, and community engagement, we strive to create sustainable solutions that address the unique needs of each child, fostering a nurturing environment that promotes health, knowledge, and opportunities for all.</p> 
             </div>

             </div>

             <div className='paragraph-wrapper'>
                <p className='small-paragraph'>Since its inception in 2021, Needevah has organized events targeted at providing for underprivileged children.
At Nedeevah Foundation, we strongly believe that one of the ways to really help a child is to think about their future while looking at their present; and the only way to achieve that future is by ensuring that they are in good health both physically and mentally to be actively invested in the present. In the past year, we have raised funds and organized events to provide food, clothes or educational materials to children and older underprivileged adults. Our most recent event is an outreach to Kuchinguro IDP camp where we equipped the children with learning materials and clothes for both young and old. This is consistent with our vision of giving back to society</p>
             </div>

    <div className='aboutOne-coreValues'>
        <h1 className='coreValues-heading'> Core Values </h1>
        <ul className='coreValues'>
            <li>Compassion</li>
            <li>Generosity</li>
            <li>Integrity</li>
            <li>Accountability</li>
            <li>Equality</li>
            <li>Justice</li>
        </ul>
    </div>
        
    </div> );
}
 
export default AboutOne;
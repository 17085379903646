import './home.css';
import { Project, Header, Headerbase, GallerySection, Join, Sdg } from '../home-container';
import { Navbar, Footer, Slidercomp } from '../components';



export default function Home () {
    
    return ( <div className='home_container'>
       <Navbar />
        <Header />
        <Headerbase />
        <Project /> 
        <Sdg />
        <GallerySection />
        <Join />
        <Slidercomp />
        <Footer />
       
    {/* <Outreach /> */}
  
    </div> );
  
}
 

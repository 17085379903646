import './aboutTwo.css';
import map from '../../assets/images/mapng.svg'

const AboutTwo = () => {
    return ( <div className='aboutTwo-container'>
        <div className='aboutTwo-content'>
        <h1>Scope</h1>
        <p className='heading-paragraph'>Northern nigeria: Abuja, Kaduna & Jos</p>
        <div className='map-container'>
            <img className='map' alt='map of Nigeria' src={map} />
        </div>
        </div>
       
    </div> );
}
 
export default AboutTwo;
import './contact.css';
import Layout from '../Layout';
import ContactHeader from '../contact-container/contactHeader/ContactHeader';
import ContactForm from '../contact-container/contact-form/ContactForm';

const Contact = () => {
    return ( 
<Layout>


    <div className='contact-container'>
        <ContactHeader />
         
     <div className='contact-content'>
        
        <ContactForm />
        
    
    </div>  

    </div> 
    </Layout>
    );
}
 
export default Contact;
import "./gallery.css";
import { useRef } from "react";
import { motion, useScroll } from "framer-motion";
import image  from '../../assets/images/gallery/image.png';
import img2  from '../../assets/images/gallery/img2.png';
import img3  from '../../assets/images/gallery/img3.png';
import img4  from '../../assets/images/gallery/img4.png';
import img5  from '../../assets/images/gallery/img5.png';
import img6  from '../../assets/images/gallery/img6.png';
import img7  from '../../assets/images/gallery/img7.png';
import img8  from '../../assets/images/gallery/img8.png';
import img9  from '../../assets/images/gallery/img9.png';
import img10  from '../../assets/images/gallery/img10.png';

export default function Gallery() {
  const ref = useRef(null);
  const { scrollXProgress } = useScroll({ container: ref });

  return (
    <div className="gallery-container">

    

      <svg id="progress" width="100" height="100" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="30" pathLength="1" className="bg" />
        <motion.circle
          cx="50"
          cy="50"
          r="30"
          pathLength="1"
          className="indicator"
          style={{ pathLength: scrollXProgress }}
        />
      </svg>
      <ul className="galleryUL" ref={ref}>
        <li className=" galleryLI " > <img className="gallery-img 1" alt="1" src={image}></img> </li>
        <li className=" galleryLI "><img className="gallery-img 2" alt="1" src={img2}></img></li>
        <li className=" galleryLI "><img className="gallery-img 3" alt="1" src={img3}></img></li>
        <li className=" galleryLI "><img className="gallery-img 4" alt="1" src={img4}></img></li>
        <li className=" galleryLI "><img className="gallery-img 5" alt="1" src={img5}></img></li>
        <li className=" galleryLI "><img className="gallery-img 6" alt="1" src={img6}></img></li>
        <li className=" galleryLI "><img className="gallery-img 7" alt="1" src={img7}></img></li>
        <li className=" galleryLI "><img className="gallery-img 8" alt="1" src={img8}></img></li>
        <li className=" galleryLI "><img className="gallery-img 9" alt="1" src={img9}></img></li>
        <li className=" galleryLI "><img className="gallery-img 10" alt="1" src={img10}></img></li>
      
    
      </ul>

    </div>
  );
}

import './footer.css'
import logo from '../../assets/nficon3.png';
import { AiOutlineTwitter, AiOutlineMail, AiFillPhone,  } from 'react-icons/ai'
import {FaLinkedinIn, FaFacebookF, FaInstagram } from 'react-icons/fa'
import { TiLocation } from 'react-icons/ti'
const Footer = () => {
    return ( <div className='footer-container'>

        <div className='footer-content'>

      
        <div className='footer-section'>
            <img className='footer-logo' src={logo} alt="" />
            <p className='footer-text'>A future, full of hope.</p>
            <div className='footer-icons social'>
                
          <a href="https://www.instagram.com/nedeevahaidfoundation/" target="_blank" rel="noreferrer"> <FaInstagram className='social-icons' color="black" size={30}  /> </a>
          <a href="https://twitter.com/Nedeevah_foundr" target="_blank" rel="noreferrer"> <AiOutlineTwitter className='social-icons' color="black" size={30} /> </a>
          <a href="https://www.linkedin.com/company/nedeevah-aid-foundation-naf/" target="_blank" rel="noreferrer"> <FaLinkedinIn className='social-icons' color="black" size={30}  /> </a> 
          <a href="https://web.facebook.com/profile.php?id=100082532741401" target="_blank" rel="noreferrer" > <FaFacebookF className='social-icons' color="black" size={30}  /> </a>

            </div>
        </div>

        <div className='footer-section'>

            <h2 className='footer-heading'>Recent Events</h2>

            <div>
          <a className='footer-links' href="/"> <p className='footer-text'>Zero Malaria Outreach</p> </a>
          <a className='footer-links' href="/"> <p className='footer-text'>Girl child outreach project</p> </a>
          <a className='footer-links' href="/"> <p className='footer-text'>Our first ever outreach program</p> </a>
            </div>
        </div>

        <div className='footer-section'>

        <h2 className='footer-heading'>Contact Us</h2>

            <div className='footer-icons contact'>
                <div className='footer-links'> <AiOutlineMail className='social-icons' color="black" size={30} /> <p className='footer-text'>nedeevahfoundation@gmail.com</p> </div>
                <div className='footer-links'> <AiFillPhone className='social-icons' color="black" size={30} /> <p className='footer-text'> +2347055243155</p> </div>
                <div className='footer-links'> <TiLocation className='social-icons' color="black" size={30} /> <p className='footer-text'> No 8119 Nzube Estate lokogoma</p> </div>
            </div>
        
        </div>
      

        </div>
        <p className='footer-text watermark'>©2022 Nedeevah Foundation. All rights reserved.</p>
    </div> );
}
 
export default Footer;
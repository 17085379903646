import './contactHeader.css'

const ContactHeader = () => {
    return (  
        <div className='contact__header-container '>
          <div className='contact-texts'>
    
              <h1 className='contact__title'>
              Contact
              </h1> 
              <p className='contact-paragraph'>Please don't hesitate to reach out to us. </p>
    
          </div>
        </div> 
        );
}
 
export default ContactHeader;
import './App.css';
import React from 'react';
import Layout from './Layout';



function App({ Component, pageProps }) {
  return (
  
          <div className='container'>
            
          <Layout>
          <Component {...pageProps} />
          </Layout>
       
          </div>
    
  );
}


export default App
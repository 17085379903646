import './causesCards.css'

const CausesCards = () => {
    return ( <div className="causesCards-container">
        <div className="causesCards-content">

       
        <div className="causes-card c1">
            <p> Advocacy </p>
        </div>

        <div className="causes-card c2">
            <p> Healthcare </p>
        </div>

        <div className="causes-card c3">
            <p> Education </p>
        </div>

        </div>
    </div> );
}
 
export default CausesCards;

import './about.css';
import Layout from '../Layout';
import { AboutHeader, AboutOne, AboutTwo, AboutThree } from '../about-container';






export default function About () {
    
    return ( 

      <Layout>
        <div className='about-container '>
        <AboutHeader />
        <AboutOne />
        <AboutTwo />
        <AboutThree />
        </div>
    </Layout>
    );
}
 

import './header.css';
import { motion } from "framer-motion";


//import headerImage from '../../assets/x-9015bw.jpg'

export default function Header () {

   

    return(
        <div className='header__container' id='Home'>

<div className='header__img-container scale-up-center' >


            <div className='header__img-div'> 
            <div className='header__background-image kenburns-bottom  '> </div>

            <motion.div  initial={{ opacity: 0 }}
  whileInView={{ opacity: 1 }} className='header__container-text'>
            <h1 className='header__title'>Nedeevah Aid Foundation</h1>
            <p className='header-paragraph'>Empowering Children Through Education and Healthcare.</p>

               <div className='buttons'>
               <button> <a href="https://docs.google.com/forms/d/e/1FAIpQLSe_GmNwTgUIker33aP72EO2dBmygfNy4f4pA4c4X-sGtXxRbg/viewform" target="_blank" rel="noreferrer">Volunteer</a></button>
           
              
             <button className="donate__button">
                    <a href="https://flutterwave.com/pay/pvw2fyvkalpi" target="_blank" rel="noreferrer">Donate</a>
                  </button>


               </div>

            </motion.div>

               </div> 
</div>

        
               
        </div>
        
    )
}

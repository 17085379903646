import './headerbase.css';
import {FaHandHoldingUsd, FaHandsHelping, FaHandHoldingHeart} from 'react-icons/fa'


export default function Headerbase () {
    return ( 
        <div className='header__base-container'>


<div className='header__base'>

   <div className='header__base-cta'>
     <div className='base__cta-icon'>
        <FaHandHoldingUsd color="white" size={30} />
     </div>
     <div className='base__cta-text'>
         <h1 className='base-title'>Give</h1>
         <p className='small-text-paragraph'>Indeed you gain more when you give more. Give today.</p>
     </div>
     
 </div>

</div>

<div className='header__base'>

   <div className='header__base-cta'>
     <div className='base__cta-icon'>
     <FaHandsHelping color="white" size={30} />
     </div>
     <div className='base__cta-text'>
         <h1 className='base-title'>Partner</h1>
         <p className='small-text-paragraph'>Partner with us on our outreaches and special events.</p>
     </div>
     
 </div>

</div>

     <div className='header__base'>

   <div className='header__base-cta'>
     <div className='base__cta-icon'>
     <FaHandHoldingHeart color="white" size={30} />
     </div>
     <div className='base__cta-text'>
         <h1 className='base-title'>Volunteer</h1>
         <p className='small-text-paragraph'>We can’t bear the burden alone. We need you.</p>
     </div>
     
 </div>

</div>

</div>
    );
}
 

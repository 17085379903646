
import { Navbar, Footer }  from "./components";
import './layout.css'


const Layout =({children}) =>{
    return(
       
        <div className="layout-container">
          <Navbar />
       {children}
        <Footer />
        </div>
    )
}

 
export default Layout;